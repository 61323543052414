<template>
    <v-menu open-on-hover left offset-x>
        <template v-slot:activator="{ on }">
            <v-tooltip top v-if="contact.activities.option">
                <template v-slot:activator="{ on }">
                    <v-chip v-if="contact.activities.option && contact.activities.option.count > 1" label small class="ml-1" v-on="on">
                        + {{ contact.activities.option.count - 1 }}
                    </v-chip>
                </template>
                Vous avez passé {{ contact.activities.option.count - 1}} autre{{ contact.activities.option.count > 2 ? 's' : '' }} option{{ contact.activities.option.count > 2 ? 's' : '' }} sur ce contact
            </v-tooltip>

            <v-chip :color="getOptionStatus(contact.activities.option.statusdetail, 'color')" class="my-1" label small dark v-on="on">
                Option - {{ getOptionStatus(contact.activities.option.statusdetail, 'statusLabel') }}
            </v-chip>
        </template>

        <v-card>
            <v-card-title :class="`py-1 white--text subtitle ${getOptionStatus(contact.activities.option.statusdetail, 'color')}`">
                {{ getOptionStatus(contact.activities.option.statusdetail, 'label') }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.option.activityDate | toDate() }} à {{ contact.activities.option.activityDate | toTime() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Date de création
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.option.program.title }} à {{ contact.activities.option.program.city }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Programme
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.option.product.reference }} à {{ contact.activities.option.fullprice | toCurrencyString() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Lot
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.option.statusdate | toDate() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ contact.activities.option.statusdatelabel }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import optionsMixin from '../../mixins/options.js';

export default {
    name: 'ContactOptionChip',

    mixins: [optionsMixin],

    props: {
        contact: { type: Object }
    },

    computed: {
        color() {
            switch (this.contact.activities.option.statusdetail) {
                case 'expired':
                case 'canceled':
                    return 'red lighten-2';
                case 'pending':
                default:
                    return 'grey lighten-2';
            }
        }
    }
};
</script>
