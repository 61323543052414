import { emailValidation, mobileValidation } from '../helpers/validations';

export default {
    data: () => ({
        saleStatuses: [
            { id: 'prereserved', label: 'Pré réservé', statusDateLabel: '', color: 'yellow darken-3', icon: 'fas fa-file' },
            { id: 'reserved', label: 'Réservé', statusDateLabel: '', color: 'light-blue', icon: 'fas fa-file-contract' },
            { id: 'sruwait', label: 'Attente SRU', statusDateLabel: '', color: 'blue', icon: 'fa fa-pause' },
            { id: 'loanwait', label: 'Attente prêt', statusDateLabel: '', color: 'cyan', icon: 'fas fa-piggy-bank' },
            { id: 'setnotaryapp', label: 'Fixer RDV notaire', statusDateLabel: '', color: 'light-green', icon: 'fa fa-calendar' },
            { id: 'notaryappset', label: 'RDV notaire fixé', statusDateLabel: '', color: 'green', icon: 'fa fa-calendar-check' },
            { id: 'signed', label: 'VEFA signée', statusDateLabel: '', color: 'teal', icon: 'fa fa-feather-alt' },
            { id: 'desisted', label: 'Désistée', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'retracted', label: 'Retracté', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'canceled', label: 'Annulée', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' },
            { id: 'resolved', label: 'Résolue', statusDateLabel: '', color: 'red lighten-2', icon: 'fas fa-stop-circle' }
        ]
    }),

    methods: {
        getSaleStatus(id, key) {
            const status = this.saleStatuses.find((s) => s.id === id);
            if (status) {
                return status[key];
            } else {
                return '';
            }
        },

        getSalePromoterSignatureDate(sale) {
            let date = null;

            if (Array.isArray(sale.signatures)) {
                const promoter = sale.signatures.find((s) => s.userType === 3);
                if (promoter && promoter.signed) {
                    date = promoter.signed;
                }
            }

            return date;
        },

        getSignatureFromBuyer(sale, buyer) {
            return sale.signatures.find((signature) => {
                return buyer.type === signature.userType && buyer.id === signature.userId;
            });
        },

        signatureProcessChecks(sale) {
            const result = { success: false, errors: [], saleErrors: [], contactsErrors: [], documentsErrors: [] };

            const fields = this.getConfig('sales.fields', {});

            if (fields.sale_purpose.enabled && fields.sale_purpose.required
                && !sale.salepurpose) {
                result.saleErrors.push({ text: `Le champ "${fields.sale_purpose.name}" est obligatoire` });
            }

            if (fields.sale_type.enabled && fields.sale_type.required
                && !sale.saletype) {
                result.saleErrors.push({ text: `Le champ "${fields.sale_type.name}" est obligatoire` });
            }

            const loan = fields.loan.enabled &&
                fields.loan.required &&
                !sale.noLoan &&
                sale.loans.filter((l) => {
                    return l.amount > 0 &&
                        l.nature &&
                        !l.nature.toLowerCase().includes('Apport');
                }).length === 0;
            if (loan) {
                result.saleErrors.push({ text: 'Au moins un prêt doit être saisi pour financer l\'acquisition' });
            }

            const deposit = fields.sale_deposit.enabled && fields.sale_deposit.required
                && sale.deposits.length === 0;
            if (deposit) {
                result.saleErrors.push({ text: 'Au moins un dépôt de garantie doit être saisi' });
            }

            if (fields.sale_planned_delivery_date.enabled && fields.sale_planned_delivery_date.required
                && !sale.plannedDeliveryDate1) {
                result.saleErrors.push({ text: `Le champ "${fields.sale_planned_delivery_date.name}" est obligatoire` });
            }

            for (const buyer of sale.buyers) {
                const isMain = buyer.type === 0;
                const name = `${buyer.title ? buyer.title : ''} ${buyer.name ? buyer.name : ''} ${buyer.firstname ? buyer.firstname : ''}`;

                if (fields.contact_title.enabled && fields.contact_title.required
                    && !buyer.title) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_title.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_name.enabled && fields.contact_name.required
                    && !buyer.name) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_name.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_firstname.enabled && fields.contact_firstname.required
                    && !buyer.firstname) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_firstname.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_email.enabled && fields.contact_email.required) {
                    if (!buyer.email) {
                        result.contactsErrors.push({ text: `Le champ "${fields.contact_email.name}" du contact ${name} est obligatoire` });
                    } else {
                        if (!emailValidation(buyer.email)) {
                            result.contactsErrors.push({ text: `Le format du champ "${fields.contact_email.name}" du contact ${name} n'est pas correct` });
                        }
                    }
                }

                if (fields.contact_mobile.enabled && fields.contact_mobile.required) {
                    if (!buyer.mobile) {
                        result.contactsErrors.push({ text: `Le champ "${fields.contact_mobile.name}" du contact ${name} est obligatoire` });
                    } else {
                        if (!mobileValidation(buyer.mobile)) {
                            result.contactsErrors.push({ text: `Le format du champ "${fields.contact_mobile.name}" du contact ${name} n'est pas correct`, type: 'mobile_format' });
                        }
                    }
                }

                if (fields.contact_address.enabled && fields.contact_address.required
                    && !buyer.address) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_address.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_zip.enabled && fields.contact_zip.required
                    && !buyer.zip) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_zip.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_city.enabled && fields.contact_city.required
                    && !buyer.city) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_city.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_birth_date.enabled && fields.contact_birth_date.required
                    && !buyer.birthdate) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_birth_date.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_profession.enabled && fields.contact_profession.required
                    && !buyer.profession) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_profession.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_birth_place.enabled && fields.contact_birth_place.required
                    && !buyer.birthplace) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_birth_place.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_family_situation.enabled && fields.contact_family_situation.required
                    && !buyer.familysituation) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_family_situation.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_csp.enabled && fields.contact_csp.required
                    && !buyer.csp) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_csp.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_children.enabled && fields.contact_children.required
                    && isMain && !buyer.children) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_children.name}" du contact ${name} est obligatoire` });
                }

                if (fields.contact_earnings_amount.enabled && fields.contact_earnings_amount.required
                    && isMain && !buyer.earningsamount) {
                    result.contactsErrors.push({ text: `Le champ "${fields.contact_earnings_amount.name}" du contact ${name} est obligatoire` });
                }

                // Verification Mariage / PACS
                if ([2, 3].includes(buyer.familysituation)) {
                    const isMariage = buyer.familysituation === 2;
                    let familySituationLabel = 'Mariage';
                    if (!isMariage) {
                        familySituationLabel = 'PACS';
                    }
                    if (fields.contact_union_place.enabled && fields.contact_union_place.required
                        && !buyer.unionplace) {
                        let label = 'Ville';
                        if (!isMariage) {
                            label = 'Tribunal d\'instance';
                        }
                        result.contactsErrors.push({ text: `Le champ "${familySituationLabel} - ${label}" du contact ${name} est obligatoire` });
                    }

                    if (fields.contact_union_date.enabled && fields.contact_union_date.required
                        && !buyer.uniondate) {
                        result.contactsErrors.push({ text: `Le champ "${familySituationLabel} - Date" du contact ${name} est obligatoire` });
                    }

                    if (fields.contact_union_type.enabled && fields.contact_union_type.required
                        && !buyer.uniontype) {
                        result.contactsErrors.push({ text: `Le champ "${familySituationLabel} - Régime" du contact ${name} est obligatoire` });
                    }

                    if (fields.contact_union_contract_date.enabled && fields.contact_union_contract_date.required
                        && buyer.unioncontract && !buyer.unioncontractdate) {
                        result.contactsErrors.push({ text: `Le champ "${familySituationLabel} - Date du contrat" du contact ${name} est obligatoire` });
                    }
                }
            }

            for (const document of sale.documents) {
                if (fields.documents_attachments.enabled && fields.documents_attachments.required
                    && document.code.startsWith('CONTRACT_ATTACHMENTS') && document.id === '0') {
                    result.documentsErrors.push({ text: `Le document "${document.title}" est obligatoire` });
                }

                if (fields.documents_financial_plan.enabled && fields.documents_financial_plan.required
                    && document.code.startsWith('CTR_FINANCIAL_PLAN') && document.id === '0') {
                    result.documentsErrors.push({ text: `Le document "${document.title}" est obligatoire` });
                }

                if (fields.documents_attachments_notices.enabled && fields.documents_attachments_notices.required
                    && document.code.startsWith('CONTRACT_ATTACHMENTS_NOTICE') && document.src === 'prg' && document.count !== '1') {
                    result.documentsErrors.push({ text: 'Le document "Notice Descriptive" est obligatoire' });
                }
            }

            if (fields.documents_attachments_vat.enabled && fields.documents_attachments_vat.required && sale.vat !== 20 && this.getConfig('sales.enable_partner_vat', false)) {
                const vatDocument = sale.documents.find((d) => d.code === 'CONTRACT_ATTACHMENTS' && d.title === 'Annexe TVA');
                if (!vatDocument) {
                    result.documentsErrors.push({ text: 'Le document "Annexe TVA" est obligatoire' });
                }
            }

            result.errors = [...result.saleErrors, ...result.contactsErrors, ...result.documentsErrors];
            result.success = result.errors.length === 0;
            return result;
        },

        canStartSignProcess(sale) {
            let bool = true;

            for (const signature of sale.signatures) {
                if (signature.sent) {
                    bool = false;
                    break;
                }
            }

            return bool;
        },

        canRestartSignProcess(sale) {
            let bool = false;

            if (this.canStartSignProcess(sale)) {
                return false;
            }

            for (const signature of sale.signatures) {
                if (signature.refused) {
                    bool = true;
                    break;
                }

                if (signature.expired) {
                    bool = true;
                    break;
                }
            }

            if (sale.canceleddate) {
                bool = true;
            }

            return bool;
        },

        isSalePinel(sale) {
            return sale.saletype === 'Investissement PINEL';
        },

        getSalePriceTTC(sale) {
            const sum = sale.products.reduce((sum, product) => {
                sum += parseFloat(product.salePrice);
                return sum;
            }, 0);
            return sum;
        },

        getSaleBadges(sale, codesStr) {
            let badges = [];

            const events = sale.signatures;
            const isWaitingDocument = (sale !== null && ['1', '2'].includes(sale.upload_status));
            const isContractGenerated = events.some((e) => e.sent && e.sent.length > 0) || isWaitingDocument;
            const isSignedElectronically = events.length > 0 && events[0].processId || isWaitingDocument;
            const isSignedByAllMembers = events.every((e) => e.signed);
            const signedCount = events.filter((e) => e.signed).length;

            if (sale.canceleddate) {
                badges.push({ code: 'cancellation', color: 'red', tooltip: 'La vente a été annulée', label: 'Vente annulée' });
            } else if (sale.retracteddate) {
                badges.push({ code: 'retractation', color: 'red', tooltip: 'Le réservataire a fait valoir son droit de rétractation', label: 'Vente rétractée' });
            } else if (sale.resolveddate) {
                badges.push({ code: 'resolution', color: 'red', tooltip: 'La vente a été résolue', label: 'Vente résolue' });
            } else if (sale.desisteddate) {
                badges.push({ code: 'withdrawal', color: 'red', tooltip: 'Le contrat de réservation a été désisté', label: 'Vente désistée' });
            } else if (events.some((e) => e.refused !== null)) {
                badges.push({ code: 'refused', color: 'red', tooltip: 'L\'un des signataires a refusé le contrat préliminaire de réservation', label: `Signature ${isSignedElectronically ? 'électronique' : 'manuelle'} refusée` });
            } else if (events.some((e) => e.expired !== null)) {
                badges.push({ code: 'expired', color: 'red', tooltip: 'L\'un des signataires n\'a pas signé à temps le contrat préliminaire de réservation', label: `Signature ${isSignedElectronically ? 'électronique' : 'manuelle'} expirée` });
            } else if (isContractGenerated) {
                const label = `Signature ${isSignedElectronically ? 'électronique' : 'manuelle'} ${signedCount} / ${events.length}`;
                const color = isSignedByAllMembers ? 'green' : 'grey lighten-1';
                const tooltip = isSignedByAllMembers ? 'Le contrat a été signé par tous les signataires' : this.notSignedTooltipContent(events, sale.buyers);
                badges.push({ code: 'signature', color, tooltip, label });

                const buyerEvents = events.filter((e) => e.userType !== '3');
                const isSruSent = buyerEvents.length > 0 && buyerEvents.some((e) => e.notificationsent);
                if (isSruSent) {
                    const sruReceivedCount = events.filter((e) => e.userType !== '3' && e.notificationreceived).length;
                    const isSruSentElectronically = buyerEvents.length > 0 && buyerEvents.some((e) => e.registeredmailid);
                    const isSruReceivedByAll = buyerEvents.length > 0 && buyerEvents.every((e) => e.notificationreceived);
                    const isSruRefusedByAny = buyerEvents.length > 0 && buyerEvents.some((e) => e.notificationrefused);
                    const isSruExpiredByAny = buyerEvents.length > 0 && buyerEvents.some((e) => e.notificationexpired);

                    const label = `Envoi SRU ${isSruSentElectronically ? 'électronique' : 'manuel'} <span class="badge badge-light"> ${sruReceivedCount} / ${buyerEvents.length} </span>`;

                    if (isSruReceivedByAll) {
                        badges.push({ color: 'green', tooltip: 'Tous les signataires ont accusé réception de la notification SRU', label });
                    } else {
                        let tooltip = null;
                        let color = 'orange';
                        if (isSruRefusedByAny && isSruExpiredByAny) {
                            tooltip = 'Certains destinataires ont refusé ou négligé la réception de la notification SRU';
                        } else if (isSruRefusedByAny) {
                            tooltip = 'Certains destinataires ont refusé la réception de la notification SRU';
                        } else if (isSruExpiredByAny) {
                            tooltip = 'Certains destinataires ont négligé la réception de la notification SRU';
                        } else {
                            tooltip = 'Certains signataires n\'ont pas encore accusé réception de la notification SRU';
                            color = 'secondary';
                        }
                        badges.push({ code: 'sru', color, tooltip, label });
                    }
                }
            }

            if (sale.noLoan) {
                badges.push({ code: 'loan', color: 'blue', tooltip: 'L\'acquéreur n\'a sollicité aucun prêt', label: 'Sans prêt' });
            }

            if (typeof codesStr === 'string') {
                const codes = codesStr.split(',').filter((code) => code !== '');
                badges = badges.filter((badge) => codes.includes(badge.code));
            }

            return badges;
        },

        notSignedTooltipContent(events, buyers) {
            let label = 'Le contrat n\'a pas été signé par tous les signataires';
            for (const event of events) {
                if (!event.signed) {
                    if (event.userType === '0') {
                        const buyer = buyers.find((buyer) => buyer.id === event.id && buyer.type === '');
                        label = `Le contrat préliminaire est en attente de signature par l'acquéreur ${buyer.title} ${buyer.name} ${buyer.firstname}`;
                        break;
                    } else if (event.userType === '1' || event.userType === '2') {
                        const buyer = buyers.find((buyer) => buyer.id === event.id && buyer.type === 'Co-acquéreur');
                        label = `Le contrat préliminaire est en attente de signature par le co-acquéreur ${buyer.title} ${buyer.name} ${buyer.firstname}`;
                        break;
                    } else if (event.userType === '3') {
                        label = 'Le contrat préliminaire est en attente de validation';
                        break;
                    } else if (event.userType === '4') {
                        label = 'Le contrat préliminaire est en attente de contre-signature';
                        break;
                    }
                }
            }
            return label;
        },

        isDocumentOfClientsCategory(document) {
            return document.code.startsWith('CTR');
        }
    }
};
