<template>
    <v-menu open-on-hover left offset-x>
        <template v-slot:activator="{ on }">
            <v-tooltip top v-if="contact.activities.sale">
                <template v-slot:activator="{ on }">
                    <v-chip v-if="contact.activities.sale && contact.activities.sale.count > 1" label small class="ml-1" v-on="on">
                        + {{ contact.activities.sale.count - 1 }}
                    </v-chip>
                </template>
                Vous avez passé {{ contact.activities.sale.count - 1}} autre{{ contact.activities.sale.count > 2 ? 's' : '' }} réservation{{ contact.activities.sale.count > 2 ? 's' : '' }} sur ce contact
            </v-tooltip>

             <v-chip :color="getSaleStatus(contact.activities.sale.status, 'color')" class="my-1" label small dark v-on="on">
                Réservation - {{ getSaleStatus(contact.activities.sale.status, 'label') }}
            </v-chip>
        </template>

        <v-card>
            <v-card-title :class="`py-1 white--text subtitle ${getSaleStatus(contact.activities.sale.status, 'color')}`">
                Réservation - {{ getSaleStatus(contact.activities.sale.status, 'label') }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.sale.activityDate | toDate() }} à {{ contact.activities.sale.activityDate | toTime() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Date de création
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.sale.program.title }} à {{ contact.activities.sale.program.city }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Programme
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.sale.product.reference }} à {{ contact.activities.sale.fullprice | toCurrencyString() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Lot
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.activities.sale.statusdate">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.sale.statusdate | toDate() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ contact.activities.sale.statusdatelabel }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import salesMixin from '../../mixins/sales.js';

export default {
    name: 'ContactSaleChip',

    mixins: [salesMixin],

    props: {
        contact: { type: Object }
    }
};
</script>
