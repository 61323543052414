export function emailValidation(email) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
}

export function mobileValidation(mobile) {
    const regexs = [
        /^(?:(?:(?:\+33|\+41|\+352|0031|0041|00352|0|\+590|00590))[6-7])(?:\d{8})$/,
        /^(?:(?:(?:\+351|00351))[9][01236])(?:\d{7})$/,
        /^(?:(?:\+1|001))(?:\d{10})$/,
        /^(?:(?:\+8869|008869))(?:\d{8})$/,
        /^(?:(?:(?:\+32|0032))[4])(?:\d{8})$/,
        /^(?:(?:(?:\+962|00962))[7])(?:\d{8})$/,
        /^(?:\+905|00905)(?:\d{9})$/,
        /^(?:\+227|00227)(?:\d{8})$/,
        /^(?:\+65|0065)(?:\d{8})$/,
        /^(?:\+447|00447)(?:\d{9})$/,
        /^(?:\+49|0049)(?:\d{10})$/,
        /^(?:\+49|0049)(?:\d{11})$/,
        /^(?:\+971|00971)(?:\d{9})$/,
        /^(?:\+3538|003538)(?:\d{8})$/,
        /^(?:\+316|00316)(?:\d{8})$/,
        /^(?:\+376|00376)(?:\d{8})$/,
        /^(?:\+377|00377)(?:\d{8})$/,
        /^(?:\+594|00594)(?:\d{9})$/,
        /^(?:\+596|00596)(?:\d{9})$/,
        /^(?:\+262|00262)(?:\d{9})$/,
        /^(?:\+687|00687)(?:\d{6})$/,
        /^(?:\+689|00689)(?:\d{8})$/,
        /^(?:\+508|00508)(?:\d{6})$/,
        /^(?:\+681|00681)(?:\d{6})$/,
        /^(?:\+852|00852)(?:\d{8})$/
    ];

    return regexs.some((regex) => regex.test(mobile));
}