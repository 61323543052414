export default {
    data: () => ({
        optionStatusesAll: [
            { id: 'pending', label: 'Option active', statusLabel: 'Active', statusDateLabel: 'Date de fin d\'option', color: 'orange lighten-2', icon: 'fas fa-hourglass-half' },
            { id: 'confirmed', label: 'Option confirmée', statusLabel: 'Confirmée', statusDateLabel: 'Date de confirmation', color: 'orange darken-3', icon: 'fas fa-check-square' },
            { id: 'canceled', label: 'Option annulée', statusLabel: 'Annulée', statusDateLabel: 'Date d\'annulation', color: 'blue-grey darken-1', icon: 'fas fa-times-circle' },
            { id: 'expired', label: 'Option expirée', statusLabel: 'Expirée', statusDateLabel: 'Date d\'expiration', color: 'blue-grey lighten-3', icon: 'fas fa-stop-circle' },
            { id: 'confirmed-expired', label: 'Option confirmée expirée', statusLabel: 'Confirmée expirée', statusDateLabel: 'Date d\'expiration', color: 'blue-grey lighten-3', icon: 'fas fa-stop-circle' },
            { id: 'to-validate', label: 'Option à valider', statusLabel: 'A valider', statusDateLabel: 'Date de l`\'option', color: 'orange lighten-2', icon: 'far fa-square' },
            { id: 'validated', label: 'Option validée', statusLabel: 'Validée', statusDateLabel: 'Date de validation', color: 'green darken-1', icon: 'fas fa-check-square' },
            { id: 'rejected', label: 'Option rejetée', statusLabel: 'Rejetée', statusDateLabel: 'Date de rejet', color: 'red darken-1', icon: 'fas fa-stop-circle' },
            { id: 'sale', label: 'Réservation', statusLabel: 'Reservé', statusDateLabel: 'Date de la réservation', color: 'green darken-1', icon: 'fas fa-check-square' },
            { id: 'sale-canceled', label: 'Réservation annulée', statusLabel: 'Reservé annulée', statusDateLabel: 'Date de la réservation', color: 'red darken-1', icon: 'fas fa-stop-circle' }
        ]
    }),

    computed: {
        optionStatuses() {
            const defaultOptions = ['pending', 'confirmed', 'canceled', 'expired', 'confirmed-expired'];
            return this.optionStatusesAll.filter((status) => {
                return this.getConfig('options.allowed_types', defaultOptions).includes(status.id);
            });
        }
    },

    methods: {
        getOptionStatus(id, key) {
            const item = this.optionStatuses.find((o) => o.id === id);
            if (item) {
                return item[key];
            } else {
                console.warn(`Unknown option status: '${id}"`);
                return '';
            }
        },

        getOptionStatusDate(option) {
            switch (option.optionstatus) {
                case 'confirmed':
                    return option.confirmationDate;
                case 'canceled':
                    if (option.desisteddate) {
                        return option.desisteddate;
                    } else if (option.retractationdate) {
                        return option.retractationdate;
                    } else if (option.canceleddate) {
                        return option.canceleddate;
                    }
                    return option.aze;
                case 'expired':
                case 'pending':
                    return option.expiredate;
            }
        },

        canConvertOptionToSale(optionStatus) {
            if (this.getConfig('sales.enabled', false)) {
                if (optionStatus === 'confirmed') {
                    return true;
                }

                if (!this.getConfig('sales.option_must_be_confirmed', false)) {
                    return optionStatus === 'pending' || optionStatus === 'validated';
                }
            }
            return false;
        },

        canConfirmOption(optionStatus) {
            return this.getConfig('options.allow_confirm', true) && (optionStatus === 'pending' || optionStatus === 'validated');
        },

        canCancelOption(optionStatus) {
            return optionStatus === 'pending'
                || optionStatus === 'validated'
                || optionStatus === 'confirmed';
        }
    }
};
