<template>
    <v-menu open-on-hover left offset-x>
        <template v-slot:activator="{ on }">
            <v-chip :color="getDenonciationStatus(contact.activities.denonciation.statusdetail, 'color')" class="my-1" label small dark v-on="on">
                Dénonciation - {{ getDenonciationStatus(contact.activities.denonciation.statusdetail, 'statusLabel') }}
            </v-chip>

            <v-tooltip top v-if="contact.activities.denonciation">
                <template v-slot:activator="{ on }">
                    <v-chip v-if="contact.activities.denonciation && contact.activities.denonciation.count > 1" label small class="ml-1" v-on="on">
                        + {{ contact.activities.denonciation.count - 1 }}
                    </v-chip>
                </template>
                Vous avez passé {{ contact.activities.denonciation.count - 1}} autre{{ contact.activities.denonciation.count > 2 ? 's' : '' }} dénonciation{{ contact.activities.denonciation.count > 2 ? 's' : '' }} sur ce contact
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title :class="`py-1 white--text subtitle ${getDenonciationStatus(contact.activities.denonciation.statusdetail, 'color')}`">
                {{ getDenonciationStatus(contact.activities.denonciation.statusdetail, 'label') }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.denonciation.activityDate | toDate() }} à {{ contact.activities.denonciation.activityDate | toTime() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Date de création
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.activities.denonciation.program.id">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.denonciation.program.title }} à {{ contact.activities.denonciation.program.city }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Programme
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.activities.denonciation.product.reference">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.denonciation.product.reference }} à {{ contact.activities.denonciation.product.price | toCurrencyString() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Lot
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.activities.denonciation.statusdate">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.denonciation.statusdate | toDate() }} à {{ contact.activities.denonciation.statusdate | toTime() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ contact.activities.denonciation.statusdatelabel }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.activities.denonciation.comments">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ contact.activities.denonciation.comments }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Commentaire
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import denonciationsMixin from '../../mixins/denonciations.js';

export default {
    name: 'ContactDenonciationChip',

    mixins: [denonciationsMixin],

    props: {
        contact: { type: Object }
    }
};
</script>
