export default {
    data: () => ({
        denonciationStatus: [
            { id: 'validated', label: 'Dénonciation validée', statusLabel: 'Validée', color: 'light-green' },
            { id: 'rejected', label: 'Dénonciation rejetée', statusLabel: 'Rejetée', color: 'red lighten-2' },
            { id: 'expired', label: 'Dénonciation expirée', statusLabel: 'Expirée', color: 'red lighten-2' },
            { id: 'pending', label: 'Dénonciation en attente de validation', statusLabel: 'En attente de validation', color: 'grey lighten-1' }
        ]
    }),

    methods: {
        getDenonciationStatus(id, key) {
            const item = this.denonciationStatus.find((o) => o.id === id);
            if (item) {
                return item[key];
            } else {
                console.warn(`Unknown denonciation status: "${id}"`);
                return '';
            }
        }
    }
};
